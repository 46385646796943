define("portal/routes/bookings/show/activity", ["exports", "@outdoorsyco/ember-shared-data/utils/activity-diff-finder"], function (_exports, _activityDiffFinder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      let controller = this.controllerFor('bookings/show/activity');
      return this.store.query('activity', {
        booking_id: model.get('id'),
        limit: 100,
        user_id: model.get('owner.id'),
        offset: 0
      }).then(activities => {
        const allResults = (0, _activityDiffFinder.activityDiffFinder)(activities.toArray(), model.presentmentCurrency);
        controller.set('activities', allResults);
      });
    }

  });

  _exports.default = _default;
});