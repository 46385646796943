define("portal/routes/users/show/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    subscription: Ember.inject.service(),

    async model() {
      let id = this.paramsFor('users.show').user_id;
      let user = await this.store.findRecord('user', id);
      let subscriptions = await this.subscription.getSubscriptions('wheelbase', {
        ownerId: user.id
      });
      return subscriptions;
    },

    actions: {
      refreshDevices() {
        this.refresh();
      },

      error(e) {
        console.log(e);
        return false;
      }

    }
  });

  _exports.default = _default;
});