define("portal/router", ["exports", "ember-router-scroll", "portal/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _emberRouterScroll.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('sessions/new');
    this.route('authenticated', {
      path: ''
    }, function () {
      // all routes that require the session to be authenticated
      this.route('users', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('show', {
          path: ':user_id'
        }, function () {
          this.route('reviews');
          this.route('rentals');
          this.route('bookings');
          this.route('drivers');
          this.route('payments');
          this.route('transactions');
          this.route('notes');
          this.route('employees');
          this.route('activity');
          this.route('affiliate');
          this.route('partners');
          this.route('credits');
          this.route('external');
          this.route('protection');
          this.route('subscriptions');
          this.route('iterable');
          this.route('notifications');
          this.route('claims');
          this.route('validations');
        });
      });
      this.route('users/index', {
        path: '/',
        resetNamespace: true
      });
      this.route('conversations/show', {
        path: '/conversations/:conversation_id',
        resetNamespace: true
      });
      this.route('bookings', {
        resetNamespace: true
      }, function () {
        this.route('show', {
          path: ':booking_id'
        }, function () {
          this.route('charges');
          this.route('edit');
          this.route('activity');
          this.route('notes');
          this.route('comms');
          this.route('external');
          this.route('drivers');
          this.route('handoff-images');
          this.route('insurance-claim');
          this.route('reviews');
          this.route('iterable');
          this.route('gl-impact');
          this.route('beta');
        });
      });
      this.route('search', {
        resetNamespace: true
      });
      this.route('rentals', {
        resetNamespace: true
      }, function () {
        this.route('show', {
          path: ':rental_id'
        }, function () {
          this.route('insurance', function () {
            this.route('certificate');
          });
          this.route('analytics');
          this.route('pricing');
          this.route('activity');
          this.route('notes');
          this.route('tags');
          this.route('competitive');
          this.route('bookings');
          this.route('blackouts');
        });
      });
      this.route('incomplete-bookings', {
        resetNamespace: true
      });
      this.route('renter-bookings', {
        resetNamespace: true
      });
      this.route('handoffs', {
        resetNamespace: true
      }, function () {});
      this.route('discount-codes', {
        resetNamespace: true
      }, function () {});
      this.route('integrations', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('show', {
          path: ':integration_id'
        });
        this.route('request_log');
      });
      this.route('bundles', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('show', {
          path: ':bundle_id'
        });
      });
      this.route('insurance-plans', {
        resetNamespace: true
      }, function () {});
      this.route('partners', {
        resetNamespace: true
      });
      this.route('rental-review', {
        resetNamespace: true
      });
      this.route('trust-safety-queue', {
        resetNamespace: true
      });
      this.route('payout-issues', {
        resetNamespace: true
      });
      this.route('user-notice-events', {
        resetNamespace: true
      });
      this.route('rental-tags', {
        resetNamespace: true
      });
      this.route('driver-checks', {
        resetNamespace: true
      }); // revenue recognition search

      this.route('impact-search', {
        resetNamespace: true
      });
      this.route('reports/insurance-report', {
        resetNamespace: true
      });
      this.route('reports/tax-forms', {
        resetNamespace: true
      });
      this.route('', {
        path: '*path'
      });
    });
  });
});