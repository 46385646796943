define("portal/controllers/users/show/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    prompt: Ember.inject.service(),
    session: Ember.inject.service(),
    subscription: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    userController: Ember.inject.controller('users.show'),
    activatedDevices: Ember.computed.filterBy('model', 'isActivated'),
    actions: {
      linkRental(id) {
        this.transitionToRoute('rentals.show', id);
        return;
      },

      async saveUserAndUpdateSubscription() {
        let owner = this.get('userController.model');
        let changed = Object.keys(owner.changedAttributes());

        if (!changed.includes('wheelbaseFee')) {
          this.flashMessages.danger('In order to update wheelbase fee, please update the value of the wheelbase fee.');
          return;
        }

        this.set('savingUserWheelbaseFee', true);
        let data = owner.serialize();
        let wheelbaseFee = data.wheelbase_fee;

        try {
          await this.adminRequest.post(`/admin/users/${owner.id}`, {
            data: {
              wheelbase_fee: wheelbaseFee
            }
          });
          let sub = this.get('subscriptions.firstObject');

          if (sub && sub.id) {
            await sub.save();
            this.set('savingUserWheelbaseFee', false);
            this.flashMessages.success('Updating the user monthly subscription fee and existing subscription have been updated.');
            return;
          }

          this.set('savingUserWheelbaseFee', false);
          this.flashMessages.success('Updating the user monthly subscription fee has been updated.');
          return;
        } catch (e) {
          console.log(e);
          this.set('savingUserWheelbaseFee', false);
          this.flashMessages.danger('An error ocurred saving owner gps fee and updating subscription');
          return;
        }
      },

      async startSubscription() {
        this.set('startingSubscription', true);

        try {
          let didSubscribe = await this.subscription.subscribe(this.get('userController.model'), 'wheelbase');

          if (didSubscribe) {
            this.send('refreshDevices');
          }
        } catch (err) {
          if (err && err.errors && err.errors[0] && err.errors[0].title) {
            this.flashMessages.danger(err.errors[0].title);
          }

          console.log(err);
        } finally {
          this.set('startingSubscription', false);
        }
      },

      async cancelSubscription() {
        this.set('cancellingSubscription', true);

        try {
          let didCancel = await this.subscription.cancel(this.get('model.firstObject'));

          if (didCancel) {
            this.send('refreshDevices');
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.set('cancellingSubscription', false);
        }
      },

      devicesChanged() {
        this.send('refreshDevices');
      },

      viewSubscription() {
        let url = `https://dashboard.stripe.com/test/subscriptions`;
        let config = Ember.getOwner(this).resolveRegistration('config:environment') || {};

        if (config.APP.runtimeEnvironment === 'production') {
          url = `https://dashboard.stripe.com/subscriptions`;
        }

        let subId = this.get('model.firstObject.subscriptionId');

        if (!subId) {
          return false;
        }

        window.open(`${url}/${subId}`, '_blank');
        return false;
      }

    }
  });

  _exports.default = _default;
});