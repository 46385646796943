define("portal/controllers/user-notice-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserNoticeEvents = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class UserNoticeEvents extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adminRequest", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "perPage", 10);

      _defineProperty(this, "total", 0);

      _defineProperty(this, "userNoticeEventsList", []);

      _defineProperty(this, "createUserNoticeEventDialog", false);

      _defineProperty(this, "userNoticeEvent", {
        name: '',
        enabled: true,
        from: null,
        to: null,
        lat: 0,
        lng: 0,
        radius: 0,
        guest_user_text: '',
        guest_need_to_acknowledge: false,
        guest_acknowledgement_text: '',
        host_user_text: '',
        host_need_to_acknowledge: false,
        host_acknowledgement_text: '',
        requires_booking_request: false,
        exclude_bundle_bookings: false
      });

      _defineProperty(this, "loadOptions", {
        id: '',
        name: '',
        enabled: true,
        from: null,
        to: null
      });

      _defineProperty(this, "orderBy", 'id');

      _defineProperty(this, "orderDirection", 'desc');
    }

    openCreateDialog() {
      this.set('createUserNoticeEventDialog', true);
    }

    closeCreateDialog() {
      this.set('createUserNoticeEventDialog', false);
      this.loadData();
    }

    setPage(page) {
      this.set('page', page);
      this.loadData();
    }

    setOrderBy(field) {
      if (field) {
        this.set('orderBy', field);

        if (this.orderDirection === 'desc') {
          this.set('orderDirection', 'asc');
        } else {
          this.set('orderDirection', 'desc');
        }
      }

      this.loadData();
    }

    toggleEnable() {
      this.set('loadOptions.enabled', !this.loadOptions.enabled);
      this.loadData();
    }

    searchWithDebounce() {
      Ember.run.debounce(this, 'send', 'loadData', 500);
    }

    loadData() {
      if (this.page <= 0) {
        this.set('page', 1);
      }

      let offset = (this.page - 1) * this.perPage;
      let data = {
        limit: this.perPage,
        offset: offset
      };

      if (this.loadOptions.enabled !== null) {
        data['enabled'] = this.loadOptions.enabled;
      }

      if (this.loadOptions.id) {
        data['ids'] = this.loadOptions.id;
      }

      if (this.loadOptions.name) {
        data['name'] = this.loadOptions.name;
      }

      if (this.loadOptions.from) {
        data['from'] = this.loadOptions.from;
      }

      if (this.loadOptions.to) {
        data['to'] = this.loadOptions.to;
      }

      if (this.orderBy && this.orderDirection) {
        if (this.orderDirection === 'desc') {
          data['order_by'] = '-' + this.orderBy;
        } else {
          data['order_by'] = '+' + this.orderBy;
        }
      }

      this.get('adminRequest').request(`user-notice-events`, {
        method: 'GET',
        data: data
      }).then(results => {
        this.set('userNoticeEventsList', results);
        const headers = results.fetchResponseMeta.headers;
        this.set('total', headers.get('Total-Results'));
      });
    }

    disable(event) {
      this.get('adminRequest').request(`user-notice-events/${event.id}`, {
        method: 'PATCH',
        data: {}
      }).then(() => {
        this.get('flashMessages').success('User Notice event ' + event.name + ' disabled');
        this.loadData();
      }).catch(() => {
        this.get('flashMessages').danger('Error when disable notice event.');
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openCreateDialog", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeCreateDialog", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeCreateDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOrderBy", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setOrderBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEnable", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEnable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchWithDebounce", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "searchWithDebounce"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadData", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disable", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "disable"), _class.prototype)), _class));
  _exports.default = UserNoticeEvents;
});