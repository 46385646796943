define("portal/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * CS Agents use a Tier system: 1, 2, 3
   * These tiers map to user.admin_level values
   * Tier 1 agents = 2
   * Trust & Safety = 3
   * Tier 3 agents = 5
   */
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    routeAfterAuthentication: 'users/index',

    beforeModel() {
      return this._loadCurrentUser();
    },

    async sessionAuthenticated() {
      let _super = this._super;
      await this._loadCurrentUser();

      if (this.session.get('redirectAfterAuthentication')) {
        _super.call(this, ...arguments);
      }
    },

    sessionInvalidated() {// handled by ember-shared-data/addon/instance-initializers/session-events.js
    },

    actions: {
      async error(error, transition) {
        const errorObject = Ember.get(error, 'errors.firstObject') || {};

        if (errorObject.status) {
          this.get('flashMessages').danger(`Error Code ${errorObject.status}: ${errorObject.title}`);
        } else {
          // It might be an application error.  Log to the console
          this.logger.error(error);
        } // If they're unauthorized, log out and transition to login page


        if ([401, 403].includes(Number(errorObject.status))) {
          this.session.set('attemptedTransition', transition);
          this.session.set('redirectAfterAuthentication', false);
          await this.session.logout();
        } // otherwise, transition to search


        this.transitionTo('search');
      }

    },

    _loadCurrentUser() {
      try {
        return this.session.loadUser();
      } catch (error) {
        this.logger.error(error);
      }
    }

  });

  _exports.default = _default;
});