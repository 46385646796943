define("portal/controllers/login", ["exports", "portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    environment: Ember.inject.service(),
    ssoLink: Ember.computed(function () {
      let ssoHost = `${_environment.default.APP.ssoHost}/saml`;
      const attemptedTransition = this.session.get('attemptedTransition');

      if (attemptedTransition) {
        let state = {
          route: attemptedTransition.targetName,
          models: [],
          queryParams: attemptedTransition.to.queryParams || {}
        }; // Traverse up the route hierarchy to collect dynamic segments (from parent params)

        let routeInfo = attemptedTransition.to;

        while (routeInfo && routeInfo.parent) {
          if (routeInfo.parent.params) {
            // Collect dynamic segments (model data) from parent params
            state.models = [...Object.values(routeInfo.parent.params), ...state.models];
          }

          routeInfo = routeInfo.parent;
        }

        const encodedState = btoa(JSON.stringify(state));
        return `${ssoHost}?r=${encodeURIComponent(encodedState)}`;
      }

      return ssoHost;
    }),
    actions: {
      async loginSuccess(userId, token) {
        this.session.set('redirectAfterAuthentication', true);
        await this.session.userLoggedIn(userId, token);
      }

    }
  });

  _exports.default = _default;
});