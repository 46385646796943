define("portal/routes/sessions/new", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    routeIfAlreadyAuthenticated: 'index',

    async model(params, transition) {
      let query = transition.to.queryParams;
      let user;

      try {
        await this.session.userLoggedIn(query.user_id, query.token);
        user = await this.session.currentUser;
      } catch (e) {
        this.transitionTo('login');
        return;
      }

      this.session.set('state', null);
      let state;

      if (query.state) {
        state = atob(query.state);
        state = JSON.parse(state);
      }

      if (state && state.route) {
        this.transitionTo(state.route, ...(state.models || []), {
          queryParams: state.queryParams || {}
        });
      } else {
        this.transitionTo('index');
      }

      return user;
    }

  });

  _exports.default = _default;
});