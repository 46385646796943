define("portal/controllers/users/show/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    adminRequest: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    emailConfirmationSent: false,
    viewAllAvailableInsurancePlans: false,
    currentDate: (0, _moment.default)(),
    csmAgents: null,
    searchEmail: '',
    insurancePlans: Ember.computed('model.{id,dealer,country,state}', 'reloadInsurancePlans', 'viewAllAvailableInsurancePlans', function () {
      if (this.get('viewAllAvailableInsurancePlans')) {
        let opts = {
          SkipHiddenCheck: true,
          SkipLocalityCheck: true,
          country: this.get('model.country') || 'US',
          locality: this.get('model.state') || '',
          OwnerID: this.get('model.id') || 0
        };

        if (this.get('model.dealer')) {
          opts['SkipDealerCheck'] = !this.get('model.dealer');
          opts['dealer_only'] = this.get('model.dealer');
        }

        return this.get('store').query('insurance-plan', opts);
      }

      return this.get('store').query('insurance-plan', {
        forRentals: true,
        owner_id: this.get('model.id')
      });
    }),
    userNeedsSaving: Ember.computed('model.hasDirtyAttributes', 'newPassword', 'newEmail', function () {
      return this.get('model.hasDirtyAttributes') || this.get('newPassword') || this.get('newEmail');
    }),
    canProvissionallyCompleteW9: Ember.computed('model.{w9TaxFormCompleted,canGetProvisionalApproval}', function () {
      return !this.get('model.w9TaxFormCompleted') && this.get('model.canGetProvisionalApproval');
    }),
    canAssignAdmin: Ember.computed('this.session.user', function () {
      return this.get('session.user.admin') && this.get('session.user.adminLevel') >= 10 && this.get('model.email').indexOf('@outdoorsy.co') > 0;
    }),
    hasAdminChanged: Ember.computed('model.{admin,adminLevel,isCsmAgent,hasDirtyAttributes}', function () {
      let changed = this.get('model').changedAttributes();
      return changed && (changed.admin || changed.adminLevel || changed.isCsmAgent);
    }),
    isChecked: Ember.computed('model.inactiveOn', function () {
      return this.get('model.inactiveOn') ? true : false;
    }),

    hideUser() {
      this.get('adminRequest').request(`admin/users/${this.get('model.id')}/hide`, {
        method: 'POST',
        data: {
          update_inactive: true
        }
      }).then(() => {
        this.get('model').reload();
      });
    },

    unhideUser() {
      this.get('adminRequest').request(`admin/users/${this.get('model.id')}/unhide`, {
        method: 'POST',
        data: {
          update_inactive: true
        }
      }).then(() => {
        this.get('model').reload();
      });
    },

    actions: {
      toggleInactive() {
        if (this.get('model.inactiveBy') === 0) {
          const user = this.get('session.user.id');
          this.set('model.inactiveBy', Number(user));
        } else {
          this.set('model.inactiveBy', 0);
        }
      },

      validateEmail() {
        this.apiRequest.post('auth/creds/resend').then(() => {
          this.set('emailConfirmationSent', true);
        });
      },

      setCurrentInsurancePlan(plan) {
        if (!plan) {
          this.set('currentInsurancePlan', null);
          return;
        }

        if (plan.get('hasOverridePlan')) {
          plan.get('insurancePlanOverride').then(op => {
            this.set('currentInsurancePlan', op);
          });
          return;
        }

        this.set('currentInsurancePlan', plan);
      },

      saveInsurancePlanOverride() {
        let overridePlan = this.get('currentInsurancePlan.isOverridePlan');

        if (overridePlan) {
          this.get('currentInsurancePlan').save().then(() => {
            this.toggleProperty('reloadInsurancePlans');
            this.set('currentInsurancePlan', null);
          });
          return;
        }

        let data = {
          insurance_plan_id: this.get('currentInsurancePlan.id'),
          owner_id: Number(this.get('model.id')),
          owner_fee_percentage: this.get('currentInsurancePlan.ownerFeePercentage'),
          renter_fee_percentage: this.get('currentInsurancePlan.renterFeePercentage'),
          dealer_owned_owner_fee_percentage: this.get('currentInsurancePlan.dealerOwnedOwnerFeePercentage'),
          dealer_owned_renter_fee_percentage: this.get('currentInsurancePlan.dealerOwnedRenterFeePercentage')
        };
        this.get('adminRequest').post(`insurance-plan-overrides`, {
          data: JSON.stringify(data)
        }).then(() => {
          this.toggleProperty('reloadInsurancePlans');
          this.set('currentInsurancePlan', null);
        });
      },

      deleteInsurancePlanOverride(plan) {
        if (!plan) {
          return;
        }

        let id = plan.get('id');
        let ipRecord = this.get('store').peekRecord('insurance-plan', plan.get('insurancePlan.id'));
        this.get('adminRequest').delete(`insurance-plan-overrides/${id}`).then(() => {
          ipRecord.set('insurancePlanOverride', null);
          ipRecord.set('hasOverridePlan', false);
          plan.unloadRecord();
          ipRecord.rollbackAttributes();
          this.toggleProperty('reloadInsurancePlans');
          this.set('currentInsurancePlan', null);
        });
      },

      provisionalCompleteW9() {
        this.get('adminRequest').request(`user-tax-forms/provisional/${this.get('model.id')}`, {
          method: 'POST',
          data: {}
        }).then(() => {
          this.get('model').reload();
        });
      },

      reSyncW9Status() {
        this.setProperties({
          resyncLoading: true
        });
        this.get('adminRequest').request(`/user-tax-forms/${this.get('model.id')}`, {
          method: 'GET'
        }).then(() => {
          this.get('model').reload();
        }).catch(err => {
          console.error(err);
          alert('W9 status failed to resync');
        }).finally(() => {
          this.set('resyncLoading', false);
        });
      },

      searchW9ToSwap() {
        this.set('searchingSimilarW9', true);
        let url = `/user-tax-forms/W9/search/${this.get('model.id')}`;

        if (this.searchEmail) {
          url += `?email=${encodeURIComponent(this.searchEmail)}`;
        }

        this.get('adminRequest').request(url, {
          method: 'GET'
        }).then(response => {
          let results = response && response.filter(item => item.has_user_completed);

          if (!results || results.length === 0) {
            alert('No similar W9s found');
          } else {
            this.set('showSimilarW9Results', true);
            this.set('similarW9Results', results);
          }
        }).catch(err => {
          console.error(err);
          alert('Failed to search for similar W9');
        }).finally(() => {
          this.set('searchingSimilarW9', false);
        });
      },

      swapW9(donorUserId) {
        let confirmed = confirm(`Before taking this action, you must confirm that the identity of the user requesting help is an account owner on both accounts (${this.get('model.id')} and ${donorUserId})`);

        if (!confirmed) {
          return;
        }

        this.set('swapping', true);
        this.set('swappingUserId', donorUserId);
        let url = `/user-tax-forms/W9/move`;
        this.get('adminRequest').request(url, {
          method: 'POST',
          data: JSON.stringify({
            donor_user_id: donorUserId,
            receiver_user_id: parseInt(this.get('model.id'))
          })
        }).then(() => {
          this.get('model').reload();
          this.set('showSwappingSuccessful', true);
          this.set('showSimilarW9Results', false);
          this.set('searchEmail', '');
        }).catch(() => {
          alert('Failed to swap W9s');
          this.set('swappingUserId', null);
        }).finally(() => {
          this.set('swapping', false);
        });
      },

      toggleShowSimilarW9Results() {
        this.toggleProperty('showSimilarW9Results');
      },

      toggleShowSwappingSuccessful() {
        this.toggleProperty('showSwappingSuccessful');
      },

      markOwnerAsFraud() {
        const ownerName = `${this.get('model.firstName')} ${this.get('model.lastName')}`;
        const ownerId = Number(this.get('model.id'));
        const confirmMsg = `Warning: This will trigger a full refund for ALL BOOKINGS and reverse any payouts to the owner: ${ownerName}, ID: ${ownerId}. This action is not reversible.`;
        let confirmed = confirm(confirmMsg);

        if (confirmed) {
          this.set('loading', true);
          this.get('adminRequest').post(`admin/users/${ownerId}/fraud`).then(() => {
            this.model.reload();
          }).catch(err => {
            let error = 'There was an error marking this owner as fraud';

            if (err && err.payload && err.payload.error) {
              error = `${error}, ${err.payload.error}`;
            }

            this.set('error', error);
          }).finally(() => {
            this.set('loading', false);
          });
        }
      },

      resetSearchMetrics() {
        this.setProperties({
          resetLoading: true,
          error: null
        });
        let data = {
          search_metrics_start_date: (0, _moment.default)().format('YYYY-MM-DD')
        };
        this.get('adminRequest').request(`admin/users/${this.get('model.id')}`, {
          data: JSON.stringify(data),
          method: 'POST'
        }).then(() => {
          this.setProperties({
            resetLoading: false,
            error: null
          });
          this.get('model').reload();
        }).catch(e => {
          let error = 'There was an error saving your changes';

          if (e && e.payload && e.payload.error) {
            error = e.payload.error;
          }

          this.setProperties({
            resetLoading: false,
            error
          });
        });
      },

      setAdmin() {
        let data = {
          admin: this.get('model.admin'),
          admin_level: Number(this.get('model.adminLevel')),
          is_csm_agent: this.get('model.isCsmAgent')
        };
        this.setProperties({
          loading: true,
          error: null
        });

        this._saveUser(data);
      },

      saveUser() {
        this.setProperties({
          loading: true,
          error: null
        });

        let validationError = this._validateFields();

        if (validationError) {
          this.setProperties({
            error: validationError,
            loading: false
          });
        } else {
          let metadata = this.get('model.metadata');

          let data = _objectSpread({
            pro: this.get('model.pro'),
            dealer: this.get('model.dealer'),
            slug: this.get('model.slug'),
            owner_fee_percentage: Number(this.get('model.ownerFeePercentage')),
            renter_fee_percentage: Number(this.get('model.renterFeePercentage')),
            addon_fee_percentage: Number(this.get('model.addonFeePercentage')),
            cc_fee_percentage: Number(this.get('model.ccFeePercentage')),
            waive_addon_fees: this.get('model.waiveAddonFees'),
            referred_renter_payout_amount: (this.get('model.referredRenterPayoutAmount') || 0) * 100,
            referred_owner_payout_amount: (this.get('model.referredOwnerPayoutAmount') || 0) * 100,
            referred_renter_credit_amount: (this.get('model.referredRenterCreditAmount') || 0) * 100,
            use_fees_increase: this.get('model.useFeesIncrease'),
            use_insurance_bundles: this.get('model.useInsuranceBundles'),
            country: this.get('model.country'),
            state: this.get('model.state'),
            phone: this.get('model.phone'),
            verify_phone: this.get('model.phoneVerified'),
            coaching_completed: this.get('model.coachingCompleted'),
            csm_agent_id: this.get('model.csmAgentId'),
            deal_id: this.get('model.dealId'),
            roamly_carshare_user: this.get('model.roamlyCarshareUser'),
            skip_w9_to_publish: this.get('model.skipW9ToPublish')
          }, metadata.od_stays_opt_in !== undefined && {
            od_stays_opt_in: metadata.od_stays_opt_in
          });

          if (this.get('newEmail')) {
            data['email'] = this.get('newEmail');
          }

          if (this.get('newPassword')) {
            data['password'] = this.get('newPassword');
          }

          if (this.get('model.coachingCompleted') && this.get('model.coachingCompletedOnDate')) {
            data.coaching_completed_on_date = (0, _moment.default)(this.get('model.coachingCompletedOnDate')).format('YYYY-MM-DD');
          } else {
            data.coaching_completed_on_date = null;
          }

          this._saveUser(data);
        }
      },

      loadCSMAgents() {
        this.get('adminRequest').request('admin/csm-agents-list').then(agents => {
          this.set('csmAgents', agents);
        }).catch(() => {
          this.set('csmAgents', []);
          this.get('flashMessages').danger('Could not fetch CSM agents list');
        });
      },

      onCSMAgentChange(event) {
        this.set('model.csmAgentId', Number(event.target.value));
      }

    },

    _validateFields() {
      let error = null;

      if (this.get('newPassword') && this.get('newPassword') !== this.get('newPasswordConfirmation')) {
        error = 'Password does not match password confirmation';
        return error;
      }

      if (this.get('model.coachingCompleted') && !this.get('model.coachingCompletedOnDate')) {
        error = 'Please insert date on which the listing coach was completed';
        return error;
      }

      if (this.get('model.coachingCompletedOnDate') && (0, _moment.default)().isBefore((0, _moment.default)(this.get('model.coachingCompletedOnDate')))) {
        error = 'Listing coach completed date cannot be in future';
        return error;
      }

      return error;
    },

    _saveUser(data) {
      this.get('adminRequest').request(`admin/users/${this.get('model.id')}`, {
        data: JSON.stringify(data),
        method: 'POST'
      }).then(data => {
        this.setProperties({
          loading: false,
          error: null,
          newEmail: null,
          newPassword: null,
          newPasswordConfirmation: null
        });

        if (Boolean(data.inactive_by) !== Boolean(this.get('model.inactiveBy'))) {
          if (this.get('model.inactiveBy')) {
            this.hideUser();
          } else {
            this.unhideUser();
          }
        } else {
          this.get('model').reload();
        }
      }).catch(e => {
        let error = 'There was an error saving your changes';

        if (e && e.payload) {
          if (e.payload.error) {
            error = e.payload.error;
          }

          if (e.payload.errors && e.payload.errors.length > 0 && e.payload.errors[0].field) {
            error = e.payload.errors[0].field;
          }
        }

        this.setProperties({
          loading: false,
          error
        });
      });
    }

  });

  _exports.default = _default;
});